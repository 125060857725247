import { setCookieLocalHost } from "./funtionsRefactor/setCookie"

/**
 *
 * @param { String } variable Key to return value of a url query variable
 * @returns String value of query variable or undefined if not found
 */
export const getQueryVariable = (variable) => {
  if (typeof window !== 'undefined') {
    const url = window.location.href
    const href = url || window.location.search
    let i
    let pair
    const query = href.split('?')[1]
    const vars = query ? query.split('&') : []

    for (i = 0; i < vars.length; i += 1) {
      pair = vars[i].split('=')

      if (pair[0] === variable && pair[1] !== '') return pair[1]
    }

    return undefined
  }
}

/**
 *
 * @returns Retorno el valor del entorno que se encuentra en tienda.js 
 */
export const getEnviroment=()=>{
  if (typeof window !== 'undefined') {
    const hostname=window.location.hostname

    if(hostname=="localhost"){
      /*Setea cookies necesarias para que funcione el script en localHost*/
       setCookieLocalHost("utag_main","v_id:018d8cf2831f0022aaa709eec0580506f002a06700bd0$_sn:2$_se:8$_ss:0$_st:1707472397314$dc_visit:2$vapi_domain:vodafone.es$ses_id:1707470360092%3Bexp-session$_pn:2%3Bexp-session$dc_event:6%3Bexp-session$dc_region:eu-central-1%3Bexp-session$dleUpToDate:true%3Bexp-session")
       setCookieLocalHost("OptanonConsent","groups=1%3A1%2C2%3A1%2C3%3A1%2C4%3A1")
       return "localHost"
    }else{
      return "vodafone"
    } 
    
  }
}



// Funcion para coger el leadId
const getVisitorId = (time)=>{
  return new Promise((resolve, reject) => {
    setInterval(() => {
      if (globalThis.Piwik) {
        // @ts-ignore
        clearInterval()
        const idVisitor = globalThis.Piwik.getAsyncTracker().getVisitorId()
        resolve(idVisitor) 
      } else {
        reject(new Error('Piwik no existe '))
      }
    },time)
  })
}



// Funcion para medir la velocidad del internet
const velocityInternet = async ()=>{
  // @ts-ignore
  var conexion = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  if (conexion && conexion.downlink) {
    var velocidad = conexion.downlink;

    // Aquí puedes definir la velocidad para considerar una conexión a 3G o menor
    var umbral3G = 2; // Mbps

    if (velocidad <= umbral3G) {
      return await getVisitorId(3000);
    } else {
      return await getVisitorId(2000);
    }
  }

}

/*SI TODO FUNCIONA EXPORTAR FUNCIONES */

// Función que ejecuta getLeadId después de un retraso de 3 milisegundos (3000 milisegundos)
// HAY QUE MEJORAR ESTO , DEBEMOS DE QUITARNOS ESE SET TIME OUT
// POSIBLES SOLUCIONES ASYNC AWAIT EN INIT.JS O CARGAR PIWIK APARTE NO EN TAG//
//Nota en los servicios de walmeric se llama idVisitor el idVisitor tambien es el idLead//

export const getLeadId = () => {
  return velocityInternet();
}

/**
 *
 * @param { String } FILE_URL Url of script to load
 * @param { Boolean } async If true, the script is loaded asynchronously
 * @param { String } type Type of the script tag
 * @returns Promise
 */
export const loadScript = (
  FILE_URL,
  async = true,
  type = 'text/javascript'
) => {
  return new Promise((resolve, reject) => {
    const scriptElement = document.createElement('script')

    scriptElement.type = type
    scriptElement.async = async
    scriptElement.src = FILE_URL

    scriptElement.onload = resolve
    scriptElement.onerror = reject
    document.head.appendChild(scriptElement)
  })
}



/**
 *
 * @param { Array } arrayPromises Array of Promises to resolve
 * @returns response
 */

export const loadMultiScript = async (arrayPromises) => {
  try {
    const promises = await Promise.all(arrayPromises)
    return promises
  } catch (error) {
    console.log(error)
  }
}

/**
 * Funcion que recibe un array de script y los inserta
 * @param { Array } arrayPromises Array of Promises to resolve with setled
 * @returns response
 */

export const loadMultiScriptSettled = (arrayPromises) => {
  Promise.allSettled(arrayPromises)
    .then((response) => console.log(response))
    .catch((error) => console.log(error))
}

/**
 *  @param {Array} jsonData Json a buscar
 *  @param {string} key dada esta key buscamos los elementos que hay duplicados con esa key
 *  @returns devuelve un array con los elementos que hemos eliminado y ordenado si el json tiene id como propiedad
 **/

export const removeDuplicatesJsonByKey = (jsonData, key) => {
  const uniqueData = []
  const seenKeys = new Set()

  jsonData.forEach((item) => {
    if (!seenKeys.has(item[key])) {
      seenKeys.add(item[key])
      uniqueData.push(item)
    }
  })

  return uniqueData
}

/**
 *  @param {Array} urls url scripst
 *  @returns carga todos los scripts contenidos en el array de url mediante Promise all
 **/

export async function loadMultiScriptsAsync(urls) {
  const promises = urls.map(url => {
    loadScript(url)
  })
  await Promise.all(promises)
}

/**
 * Funcion que retorna el valor de la cookie analitica que se recoge en el utag
 * @returns {string} valor de la cookie
 */

export const getUtagAnalitica = (cookieAnalitica) => {
  try {
    const cookieAnaliticaInformada=cookieAnalitica
    const gdpr=globalThis.utag?globalThis.utag.gdpr:""
    if(cookieAnaliticaInformada){
      return "acepta cookie analítica"
    }else{
      if (gdpr) {
        const cookieValues = globalThis.utag.gdpr.getCookieValues();
        const valueAnalitica = cookieValues["c1"] || "";
        return valueAnalitica === "1" ? "acepta cookie analítica" : "no acepta cookie analítica";
      } 
    }
   
  } catch (error) {
    
    console.error("Error al obtener la cookie analítica:", error);
    return "Error al obtener la cookie analítica"
  }
};
