
/***
 * Funcion que recibe un evento y el valor . Dicho valor se pasa como variable global y luego se recoge para meterlo
*/
export function captureLocationCta(event, valueLocation) {
    const btn = event.target;
    const elementCta = btn.getAttribute("data-analytics-element")!="undefined" ?  btn.getAttribute("data-analytics-element"):"NO TIENE UN ELEMENT CTA ASOCIADO"; // Boton donde se lanza la funcion (futuros proyectos)
    
    globalThis.location_cta_personalizacion=valueLocation || " ";
    globalThis.element_cta=elementCta
    return {"location_personalization":valueLocation, "element_cta":elementCta}
}